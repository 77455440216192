<!-- eslint-disable import/no-cycle -->
<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="MapPinIcon"
          :statistic.sync="statistiques.offreEnAttente"
          statistic-title="Offres en attente"
          color="info"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="FilterIcon"
          :statistic="statistiques.offreEnCours"
          statistic-title="Offres en négociation"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="CheckIcon"
          :statistic="statistiques.offreRejete"
          statistic-title="Offres rejetées"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="CheckSquareIcon"
          :statistic="statistiques.offreCloturee"
          statistic-title="Offres clôturées"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="primary"
          icon="FileTextIcon"
          :statistic="statistiquesDevis.affreteurDevisRecus"
          statistic-title="Devis reçus"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TrendingDownIcon"
          :statistic="statistiquesDevis.affreteurDevisAcceptes"
          statistic-title="Devis acceptés"
          color="danger"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="FileTextIcon"
          :statistic="statistiquesDevis.affreteurDevisRejete"
          statistic-title="Devis rejetés"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="FileTextIcon"
          :statistic="statistiquesInvoices.facturesRecues"
          statistic-title="Factures reçues"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { onBeforeMount } from '@vue/composition-api'

import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useInvoices from '@/services/invoice/invoiceService'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical
  },
  setup() {
    const {
      getOffersByStateNotValidated, statistiques, getOffersByStateEnCours, getOffersByStateAnnule, getOffersByStateEnded,
    } = useOffres()
    const {
      getAllDevisByAffreteurAndAccepte, statistiquesDevis, getAllDevisByAffreteurAndState, getAllDevisByAffreteurAndRejete,
    } = useSocieteTransporteurs()
    const {
      getInvoicesByAffreteur, statistiquesInvoices,
    } = useInvoices()
    onBeforeMount(async () => {
      await getOffersByStateNotValidated()
      await getOffersByStateEnCours()
      await getOffersByStateAnnule()
      await getOffersByStateEnded()
      await getAllDevisByAffreteurAndAccepte()
      await getAllDevisByAffreteurAndState()
      await getAllDevisByAffreteurAndRejete()
      await getInvoicesByAffreteur()
    })
    return {
      statistiques,
      statistiquesDevis,
      statistiquesInvoices
    }
  },
}
</script>
